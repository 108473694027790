<template>
    <div class="sub-menu-component global-inline-block">
        <div class="global-inline-block" v-for="(item,index) of list" :key="index">
            <!-- 菜单 -->
            <el-submenu :index="item.path" v-if="item.children && item.children.length>0 && !item.meta.hidden && item.meta.permission && !item.meta.no_dir">
                <template #title>
                    <i :class="item.meta.icon"></i>
                    <span>{{item.meta.title}}</span>
                </template>
                <subMenuComponent :list="item.children"/>
            </el-submenu>
            <router-link :to="item.path" class="router-link" v-else-if="!item.meta.hidden && item.meta.permission">
                <el-menu-item :index="item.path">
                    <template #title>
                        <i :class="item.meta.icon"></i>
                        <span>{{item.meta.title}}</span>
                    </template>
                </el-menu-item>
            </router-link>
        </div>
    </div>
</template>
<script>
export default({
    name:'subMenuComponent',
    props:{
        list:{
            type:Array,
            default:[]
        },
        // menu_index:{
        //     type:String,
        //     default:''
        // }
    },
    methods:{
        toPath(item){
            if(item.path != this.$router.currentRoute.value.path){
                this.$router.push({
                    path:item.path
                })
            }
        }
    }
})
</script>
<style lang="scss" scoped>
.sub-menu-component{
    .router-link{
        color: white;
        text-decoration: none;
    }
}
</style>