<template>
  <div id="pages-index">
    <nav-bar-component></nav-bar-component>
    <router-view></router-view>
  </div>
</template>
<script>
import navBarComponent from '@/components/navBar/index.vue'
export default {
  name:'app',
  components:{
    navBarComponent
  }
}
</script>
<style lang="scss" scoped>

</style>
