<template>
    <div class="nav-bar-component" id="nav-bar-component">
        <div class="box box-height">
            <div class="left">
                <div class="left-logo">
                    <img class="merchant-logo" v-if="merchant_info.merchants_logo" :src="merchant_info.merchants_logo">
                    <span class="title">{{merchant_info.merchants_short_name || '爱娱酒吧'}}</span>
                </div>
                <div class="nav-list">
                    <el-menu
                        mode="horizontal"
                        :default-active="default_active"
                        class="el-menu-vertical-demo"
                        @open="handleOpen"
                        @close="handleClose"
                        background-color="#4a5259"
                        text-color="#fff"
                        active-text-color="#ffd04b">
                        <subMenuComponent :list="router_list"></subMenuComponent>
                    </el-menu>
                    <!-- <router-link v-for="(item,index) of nav_list" :key="index" class="nav-item" :to="item.path">
                        <h3 class="nav-title">{{item.title}}</h3>
                        <h3 class="nav-sub-title">{{item.sub_title}}</h3>
                    </router-link> -->
                </div>
            </div>
            <!-- <p style="padding:4px;background-color:white;color:red;margin-left:10px;font-weight:bold;font-size:20px;width:500px;">各位用户：您现在登录的是抢先体验版，本版本仅供抢先体验功能使用，非线上正式运营版本。</p> -->
            <div class="right">
                <span class="iconfont icon-dayinji"></span>
                <span class="iconfont icon-shezhi"></span>
                <!-- <span class="merchant-before" @click="toAfter">前往商家后台</span> -->
                <div class="avatar-box">
                    <el-avatar :size="36" :src="user_info.pic"></el-avatar>
                </div>
                <div class="dropdown-box">
                    <el-dropdown :hide-on-click="false">
                        <div class="el-dropdown-link">
                            <div>
                                <div>{{user_info.realname}}</div>
                                <div>
                                    <i>{{user_info.all_department_name || '--'}}</i>
                                    <i v-if="user_info.role_name">-{{user_info.role_name}}</i>
                                </div>
                            </div>
                            <div>
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </div>
                        </div>
                        <template #dropdown>
                            <el-dropdown-menu slot="dropdown">
                                <!-- <el-dropdown-item>修改信息</el-dropdown-item> -->
                                <el-dropdown-item @click="exitLogin">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <div class="box-height"></div>
    </div>
</template>
<script>
import * as types from '@/store/types'
import {loginApi} from '@/api'
import subMenuComponent from './subMenu.vue'
let system_router_list = null
export default {
    name:'nav-bar-component',
    components:{
        subMenuComponent
    },
    data(){
        return{
            user_info:{},
            nav_list:[
                {
                    title:'台位',
                    path:'/cashier'
                },
                {
                    title:'会员',
                    path:'/member'
                },
                {
                    title:'收银',
                    path:'/pubDesk'
                },
                {
                    title:'存酒管理',
                    sub_title:'存酒仓库',
                    path:'/wineWarehouse'
                },
                {
                    title:'出品',
                    sub_title:'出品方功能',
                    path:'/produce'
                }
            ],
            merchant_info:{
                merchants_logo:'',
                merchants_short_name:'',
            },
            default_active:'',//选中的菜单
            router_list:[], //all routes
            menu_list_auth:[],
            all_available_menu: [],
        }
    },
    mounted(){
        this.staffLoginUserInfo()
        this.merchantSetIndex()
        this.getRoutes()
    },
    methods:{
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        getRoutes(){
            if(this.$router.options.routes && this.$router.options.routes[1]){
                for(let i in this.$router.options.routes){
                    this.default_active = this.$router.currentRoute.value.path
                    if(this.$router.options.routes[i].name == 'pages'){
                        let router_list = JSON.parse(JSON.stringify(this.$router.options.routes[i].children))
                        let menu_list_auth =  (this.$store.state.userData && this.$store.state.userData.authInfo) ? this.$store.state.userData.authInfo : []
                        this.menu_list_auth = this.formatMenuAuthList(JSON.parse(JSON.stringify(menu_list_auth)))
                        system_router_list = this.$router.getRoutes()
                        router_list = this.setRouter(router_list,{name:'pages'})
                        this.router_list = router_list
                        this.getHas(router_list)
                        localStorage.setItem('all_available_menu',JSON.stringify(this.all_available_menu))
                    }
                }
            }
        },
        setRouter(list,parent){
            for(let a in list){
                // 设置权限permission_menu、permission
                for(let auth in this.menu_list_auth){
                    if(list[a].name === this.menu_list_auth[auth].front_name){
                        list[a].meta.permission = true //开放菜单
                        if(this.menu_list_auth[auth].meta){ //开放按钮
                            list[a]['meta']['permission_menu'] =  this.menu_list_auth[auth].meta.permission_menu
                        }
                        break;
                    }
                }

                if(list[a].children && list[a].children.length>0){
                    
                    let redirect = ''
                    for(let b in list[a].children){
                        // 循环下一级
                        list[a].children = setRouter(list[a].children,list[a])

                        if(list[a].children[b].meta.permission){
                            if(!redirect){
                                redirect = list[a].children[b].path
                            }
                        }
                    }

                    // 系统默认的redirect有可能无权限，这里修改redirect到有权限的页面
                    if(list[a].redirect){
                        list[a].redirect = redirect
                    }
                }
                
                for(let s in system_router_list){
                    if(system_router_list[s].name === list[a].name){
                        let components = system_router_list[s].components
                        list[a].components = components
                        break;
                    }
                }
                this.$router.addRoute(parent.name,list[a])
            }
            return list
        },
        getHas(list){
            for(let i in list){
                let item = JSON.parse(JSON.stringify(list[i]))
                item.children = null
                item.components = null
                this.all_available_menu.push(item)
                if(list[i].children && list[i].children.length>0){
                    this.getHas(list[i].children)
                }
            }
        },
        formatMenuAuthList(list){
            for(let a in list){
                if(list[a].children && list[a].children.length>0){
                    let permission_menu = []
                    for(let b = list[a].children.length-1;b>=0;b--){
                        if(list[a].children[b].is_menu == 1){
                            permission_menu.push(list[a].children[b].front_name)
                            list[a].children.splice(b,1)
                        }
                    }                    

                    if(!list[a]['meta']){
                        list[a]['meta'] = {}
                    }
                    list[a]['meta']['permission_menu'] = permission_menu;

                    if(list[a].children<=0){
                        list[a].children = null
                    }
                    if(list[a].children && list[a].children.length>0){
                        list = list.concat(JSON.parse(JSON.stringify(list[a].children)))
                        list[a].children = null
                    }
                    list = this.formatMenuAuthList(list)
                }
            }
            return list
        },
        merchantSetIndex(){
            loginApi.merchantSetIndex().then(res=>{
                for(let i in res.data){
                    if(res.data[i].name === 'merchants_logo'){
                        this.merchant_info.merchants_logo = res.data[i].value
                    }else if(res.data[i].name === 'merchants_short_name'){
                        this.merchant_info.merchants_short_name = res.data[i].value
                    }
                }
            })
        },
        staffLoginUserInfo(){
            loginApi.staffLoginUserInfo().then(res=>{
                if(res.data.user_department){
                    let all_department_name = []
                    for(let i in res.data.user_department){
                        all_department_name.push(res.data.user_department[i].department_name)
                    }
                    res.data['all_department_name'] = all_department_name.toString()
                }
                this.user_info = res.data
            })
        },
        exitLogin(){
            localStorage.removeItem('all_available_menu')
            this.$store.commit(types.REMOVE_USERDATA)
            this.$router.push({
                path:'/login'
            })
        },
        toAfter(){
            window.open('https://uaiyuma.swk100.cn/','_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
.nav-bar-component{
    
    .box-height{
        height:60px;
    }
    .box{
        background: rgb(74, 82, 89);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:0px 50px;
        position: fixed;
        top:0;
        left:0;
        right:0;
        z-index:11;
        .left{
            display: flex;
            align-items: center;
            .left-logo{
                display: inline-flex;
                align-items: center;
                .merchant-logo{
                    width: 34px;
                    height: 34px;
                    border-radius: 6px;
                    margin-right: 6px;
                }
                .title{
                    font-size:20px;
                    color: white;
                }
            }
            .nav-list{
                margin-left:100px;
                display: flex;
                & /deep/ .el-menu.el-menu--horizontal{
                    border-bottom:none;
                }
                // margin-top:10px;
                .nav-item{
                    margin:0px 10px;
                    padding:0px 10px;
                    text-align: center;
                    .nav-title{
                        font-size:14px;
                        color: white;
                    }
                    .nav-sub-title{
                        height: 10px;
                        margin-top:5px;
                        font-size:10px;
                        color: rgb(168, 168, 168);
                    }
                    &.router-link-active{
                        position: relative;
                        .nav-title{
                            color: rgb(255, 202, 66);
                            position: relative;
                        }
                        &:after{
                            content:"";
                            height: 2px;
                            background: rgb(255, 202, 66);
                            position: absolute;
                            bottom:-10px;
                            left:0;
                            right:0;
                        }
                    }
                }
                a{
                    text-decoration: none;
                }
            }
        }
        .right{
            display: flex;
            align-items: center;
            .merchant-before{
                // color: rgb(41, 141, 248);
                color: white;
                margin-right: 20px;
                text-decoration: underline;
                cursor: pointer;
                font-size:12px;
            }
            .iconfont{
                color: white;
                margin: 0px 10px;
                font-size:20px;
                cursor: pointer;
            }
            .avatar-box{
                margin-left:10px;
            }
            .dropdown-box{
                color:white;
                margin-left:10px;
                .el-dropdown-link {
                    cursor: pointer;
                    color: white;
                    display: flex;
                    align-items: center;
                }
                .el-icon-arrow-down {
                    font-size: 12px;
                }
            }
        }
    }
}
</style>